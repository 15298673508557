/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    Card_mobile_image1: file(
      relativePath: { eq: "loodgieter/loodgieter-7.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Card_mobile_image2: file(
      relativePath: { eq: "loodgieter/loodgieter.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_mobile_image: file(
      relativePath: { eq: "loodgieter/loodgieter-4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_desktop_image: file(
      relativePath: { eq: "loodgieter/loodgieter-4.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Loodgieter`,
  textWithLink: () => (
    <p>
      Als loodgieter verzorgen wij al uw ‘natte’ installaties. Voor alle
      installatiewerk, onderhoud en service op het gebied van water en sanitair
      bent u bij ons aan het juiste adres. Wij verzorgen de aanleg van uw
      waterleiding van afvoeren van A tot Z. Heeft u een lekkage? Neem dan{' '}
      <AniLink fade to='/contact'>
        contact
      </AniLink>{' '}
      op en wij helpen u graag verder.
    </p>
  ),
};

const textBottom = {
  textWithLink: () => (
    <AniLink fade to='/loodgieterswerk' className='ml-sm-4x'>
      <Button
        role='button'
        variant='contained'
        className='btn btn--secondary heading__button'
      >
        <i className='material-icons mr-1x'>link</i>Terug naar overzicht
      </Button>
    </AniLink>
  ),
};

const Loodgieter = ({ data, path, location }) => {
  const seo = {
    title: `Loodgieter`,
    description: `E S I - Installtatietechniek verzorgt al uw ‘natte’ installaties. Voor alle
    installatiewerk, onderhoud en service op het gebied van water en sanitair
    bent u bij ons aan het juiste adres`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.Card_mobile_image1.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Gas installatie',
    inner:
      'Een veilige gasinstallatie is van levensbelang. E S I is gecertificeerd voor gas installatie. Dat is voor u de garantie dat wij uw gasleiding veilig en volgens alle geldende normen en voorschriften aanleggen. Schakel daarom voor aanleg van uw gasleidingen een specialist in. En zorg ervoor dat uw gasinstallatie al het benodigde regulier onderhoud krijgt.',
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.Card_mobile_image2.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Riolering',
    textWithLink: () => (
      <div>
        <p>
          Wilt u een nieuwe riolering aanleggen of uw bestaande riolering
          vernieuwen? Ook daarbij helpen wij u graag verder. Neem direct{' '}
          <AniLink fade to='/contact'>
            contact
          </AniLink>{' '}
          op en wij zorgen voor de rest!
        </p>
        <div className='hidden-sm hidden-md hidden-lg'>
          <a href='tel:0642090304'>
            <Button
              variant='contained'
              className='btn btn--secondary heading__button mt-4x '
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </a>
        </div>
        <AniLink fade to='/contact' className='hidden-xs'>
          <Button
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </AniLink>
      </div>
    ),
  };

  const sources = [
    data.Ribbon_mobile_image.childImageSharp.fluid,
    {
      ...data.Ribbon_desktop_image.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw loodgieterswerk`,
    pitchLine: `Laat al uw loodgieterswerk over aan onze professionals.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        textBottom={textBottom}
      />
    </>
  );
};

Loodgieter.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Loodgieter;
